import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence, m } from 'framer-motion';

import { breakpoints } from '@/components/common/breakpoints';
import { Icon } from '@/components/common/Icon';
import { PortableText } from '@/components/common/utils';
import { useMatchMedia } from '@/lib/useMatchMedia';

import type {
  BackgroundColourTokenType,
  SurfaceColourTokenType,
} from '@/types/colours';
import type { BlockWithAudience } from '@/types/shared';
import type { Dispatch, SetStateAction } from 'react';
import type { SanityBlock, SanityKeyed } from 'sanity-codegen';

interface AccordionProps extends BlockWithAudience {
  accordionItems: Array<SanityKeyed<object>>;
  /* 
  - singleItem -> allows one item to be opened at the time
  - allItems -> allows all items to be opened 
  */
  accordionBehaviour: 'singleItem' | 'allItems';
  openItemsState?: [number[], Dispatch<SetStateAction<number[]>>];
  backgroundColourToken?: BackgroundColourTokenType | SurfaceColourTokenType;
}

const Accordion = ({
  accordionItems,
  accordionBehaviour = 'singleItem',
  openItemsState,
  backgroundColourToken,
  pageAudience,
  isAudienceSwitcherEnabled,
}: AccordionProps) => {
  return (
    <StyledAccordionList backgroundColourToken={backgroundColourToken}>
      {accordionItems?.map((item, idx) => (
        <AccordionItem
          accordionBehaviour={accordionBehaviour}
          key={item._key}
          singleItemBehaviourConfig={{ idx, openItemsState }}
          pageAudience={pageAudience}
          isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
          {...item}
        />
      ))}
    </StyledAccordionList>
  );
};

const singleItemBehaviour = (
  singleItemBehaviourConfig: {
    idx: number;
    openItemsState: [number[], Dispatch<SetStateAction<number[]>>];
  },
  isMobile: boolean,
  memo: boolean,
) => {
  const idx = singleItemBehaviourConfig.idx;
  const [openItems, setOpenItems] = singleItemBehaviourConfig.openItemsState;

  const handleToggleSingleItemBehaviour = (idx: number) => {
    if (isMobile) {
      setOpenItems((prev) =>
        openItems.includes(idx)
          ? prev.filter((item) => item !== idx)
          : [...prev, idx],
      );
    } else {
      setOpenItems([idx]);
    }
  };

  return {
    ariaExpanded: openItems.includes(idx),
    isOpen: memo,
    onClick: () => handleToggleSingleItemBehaviour(idx),
  };
};

const allItemsBehaviour = (
  allItemsState: [boolean, Dispatch<SetStateAction<boolean>>],
) => {
  const [isOpen, setIsOpen] = allItemsState;
  return {
    ariaExpanded: isOpen,
    onClick: () => setIsOpen((prev) => !prev),
    isOpen: isOpen,
  };
};

interface AccordionItemProps extends BlockWithAudience {
  _key: string;
  heading?: string;
  body?: Array<SanityKeyed<SanityBlock>>;
  accordionBehaviour: 'singleItem' | 'allItems';
  /* Expected configuration to receive with 'singleItem' accordionBehaviour.
  - idx -> index of current accordionItem
  - openItemsState -> useState to control 1 open item at the time.
  */
  singleItemBehaviourConfig?: {
    idx: number;
    openItemsState: [number[], Dispatch<SetStateAction<number[]>>];
  };
  trackingTag?: { blockName: string; blockAction: string };
}

const AccordionItem = ({
  _key,
  heading,
  body,
  accordionBehaviour,
  singleItemBehaviourConfig,
  trackingTag,
  pageAudience,
  isAudienceSwitcherEnabled,
}: AccordionItemProps) => {
  // Configuration for the single item behaviour
  const isMobile = useMatchMedia(breakpoints.md);

  const idx = singleItemBehaviourConfig.idx;
  const openItems = singleItemBehaviourConfig.openItemsState?.[0];
  const memo = useMemo(() => openItems?.includes(idx), [idx, openItems]);

  // Configuration for the all items behaviour
  const allItemsState = useState(false);

  const handleToggle = () => {
    switch (accordionBehaviour) {
      case 'singleItem': {
        return singleItemBehaviour(singleItemBehaviourConfig, isMobile, memo);
      }
      case 'allItems':
        return allItemsBehaviour(allItemsState);
    }
  };
  const { ariaExpanded, onClick, isOpen } = handleToggle();

  return (
    <ContentWrapper>
      <ItemWrapper>
        <ItemButton
          type="button"
          aria-label={heading}
          aria-expanded={ariaExpanded}
          aria-controls={`item${_key}`}
          id={`accordion${_key}id`}
          className={
            trackingTag?.blockName &&
            `tracking-${trackingTag.blockName}__${trackingTag.blockAction}`
          }
          onClick={onClick}
        >
          <TitleWrapper>{heading}</TitleWrapper>
          <ChevronWrapper isOpen={isOpen}>
            <Icon
              icon="ChevronDown"
              colour={isOpen ? '--icon-action' : '--icon-inverse-strong'}
              size="1.5rem"
            />
          </ChevronWrapper>
        </ItemButton>
      </ItemWrapper>
      <AnimatePresence initial={false}>
        {isOpen && (
          <BodyWrapper
            id={`item${_key}`}
            aria-labelledby={`accordion${_key}id`}
            key="content"
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
            exit="closed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              closed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 0.5,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
          >
            <PortableText
              value={body}
              audienceConfig={{ pageAudience, isAudienceSwitcherEnabled }}
            />
          </BodyWrapper>
        )}
      </AnimatePresence>
    </ContentWrapper>
  );
};

const ItemButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  text-align: left;
  color: var(--text-strong);
  cursor: pointer;
  font: inherit;
`;

const BodyWrapper = styled(m.div)`
  overflow: hidden;
`;

const TitleWrapper = styled.span`
  display: flex;
  flex-wrap: wrap;
`;

const ChevronWrapper = styled.div<{ isOpen: boolean }>`
  border: 1.5px solid var(--icon-action);
  border-radius: 50%;
  padding: var(--spacing-150);
  transition: background-color 0.6s;
  background-color: ${({ isOpen }) =>
    isOpen ? 'transparent' : 'var(--icon-action)'};

  svg {
    transition: transform 0.6s;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
  }
`;

const ItemWrapper = styled.h3`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.li`
  &:not(:last-child) {
    border-bottom: 1px solid var(--border-warm-subtle);
  }
`;

const StyledAccordionList = styled.ul<{
  backgroundColourToken: BackgroundColourTokenType | SurfaceColourTokenType;
}>`
  ${({ backgroundColourToken }) =>
    backgroundColourToken && `background-color: var(${backgroundColourToken});`}

  li {
    color: var(--text-strong);
  }
`;

export { Accordion };
